import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ContentCopy,
  Info,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { IndexModal } from "../../EventCalender/components";
import { MonthPicker, useAuth } from "../../sdk";
import { MonthDayCard } from "./NewCalendarBoxes";
import { NewCalendarCopyModal } from "./NewCalendarCopyModal";
import { format } from "date-fns";
import { useBaseDemandLevel } from "../../EventCalender/hooks";
import { NewCalendarCityDemandModal } from "./NewCalendarCityDemandModal";
import { Wrapper } from "./Styles";
import { getISODate } from "../../sdk";
import { PreciumDisabledPage } from "../../sdk/components/PreciumDiabledPage";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const initialDL = {
  CityDemandLevel: false,
  CityEvent: false,
};

export const ForecastPage = styled(Box)`
  padding: 70px 20px 20px 70px;
  width: 100%;
`;

export const NewCalendar = ({ setPageHeader }) => {
  useEffect(() => {
    setPageHeader("Event Calendar");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  const [indexState, setIndexState] = useState(false);
  const { hotelId } = useParams();
  const { permission, hotels, token, authFetch, currentHotel } = useAuth();
  const [initialDate, setInitialDate] = useState(new Date());
  const [allhotels, setAllHotels] = useState([]);
  const [day, setDay] = useState(undefined);
  const [isSetModalOpen, setSetModalOpen] = useState(false);
  const [month, setMonth] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [copyOpen, setCopyOpen] = useState(false);
  const [selectDL, setSelectDL] = useState([]);
  const [updateSelectedDL, setUpdateSelectedDL] = useState(initialDL);
  const [dLCheckbox, setDLCheckbox] = useState(false);
  const [cityDemandLevelState, setCityDemandLevelState] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [copyFromHotelId, setCopyFromHotelId] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  const [isChangeDL, setIsChangeDl] = useState(false);
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const [haveData, setHaveData] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const [tempArray, setTempArray] = useState([]);
  const [tempValue, setTempValue] = useState(1);

  const [dateRangeState, setDateRangeState] = useState(false);
  const [isMonthUpdated, setIsMonthUpdated] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [popoverData, setPopOverData] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [remarkData, setRemarkData] = useState([]);
  let eventsCalendarView = null,
    addEvent = null,
    addDemandLevel = null,
    addCityDemandLevel = null,
    eventCalendarCopy = null,
    isAdmin = false;

  const getData = async (shouldSet) => {
    setIsMonthUpdated(true);
    setHasData(false);
    if (month !== undefined) {
      const date = new Date(selectedDate);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/base-demand-level/range?date=${format(
          date,
          "yyyy-MM-dd"
        )}`,
      });

      const { data, error } = await get();
      if (data && shouldSet?.val) {
        setHotelDayDetail(data);
        setIsMonthUpdated(false);
        if (data?.baseDemandLevels?.length !== 0) {
          setHasData(true);
        } else {
          setHasData(false);
        }
      } else {
        console.log(error);
      }
    } else {
      setHotelDayDetail(hotels);
    }
  };

  const {
    currentHotelId,
    demandLevels,
    isInEditMode,
    toggleEdit,
    onChange,
    generateBatchBaseDemandLevels,
    setApiHit,
    isApiHit,
    setRenderIteration,
    renderIteration,
    setSelectedDate,
    selectedDate,
  } = useBaseDemandLevel(
    setCopyFromHotelId,
    setCheckbox,
    setIsChangeDl,
    isChangeDL,
    dLCheckbox,
    updateSelectedDL,
    selectDL,
    startDate,
    endDate,
    checkbox,
    copyFromHotelId,
    setCopyModalOpen,
    setHaveData,
    setLoading,
    setFirstRender,
    getData
  );

  useEffect(() => {
    let day = initialDate.getDate();
    let month = initialDate.getMonth();
    let year = initialDate.getFullYear();
    setDay(day);
    setMonth(month);
    setYear(year);
  }, [initialDate]);

  const [hotelDayDetail, setHotelDayDetail] = useState();
  const [isComparitive, setIsComparitive] = useState(false);
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 8))
  );

  const handleIndexState = useCallback(() => {
    setIndexState(!indexState);
  }, [indexState]);

  const handleCopy = useCallback(() => {
    setCopyOpen(!copyOpen);
  }, [copyOpen]);

  useEffect(() => {
    let shouldSet = { val: true };
    getData(shouldSet);
    setApiHit(false);
    setRenderIteration(renderIteration + 1);
    return () => {
      shouldSet.val = false;
    };
  }, [
    authFetch,
    hotelId,
    hotels,
    month,
    day,
    year,
    isAdmin,
    isApiHit,
    selectedDate,
  ]);

  const RemarkData = async () => {
    const yyyymmdd = getISODate(selectedDate).split("T")[0];
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/remark/${yyyymmdd}`,
    });
    const { data, error } = await get();
    if (data) {
      setRemarkData(data);
      console.log(data, data.length);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    RemarkData();
  }, [token]);

  const uploadImage = async (images) => {
    const UploadSuccess = await Promise.all(
      images.map((file) => {
        return new Promise(async (res) => {
          try {
            const yyyymmdd = getISODate(selectedDate).split("T")[0];
            const formData = new FormData();
            formData.append("date", yyyymmdd);
            formData.append("image", file);
            const { post } = await authFetch({
              path: `/hotel/${hotelId}/upload-image`,
            });
            const { data, error } = await post(formData);
            if (error) {
              throw new Error("Failed to upload image");
            }
            res({ id: data.id, imageURL: data.imageURL });
          } catch (error) {
            res({ id: "", imageURL: "" });
          }
        });
      })
    );
    return UploadSuccess;
  };

  async function sendRemark(date, popOver) {
    const data = {
      description: popOver?.remark?.remark ?? "",
      imageId: [
        ...(!!selectedImages?.length ? selectedImages : []),
        ...(!!popOver?.images?.length ? popOver?.images : []),
      ].map((item) => item.id),
      date: date,
    };

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/remark`,
    });
    const { data: responseData, error } = await post(data);
    if (data) {
      RemarkData();
    }
  }

  useEffect(() => {
    (async function getAllHotels() {
      if (true) {
        const { get } = await authFetch({
          path: `/hotel`,
        });
        const { data, error } = await get();
        if (data) {
          setAllHotels(data);
        } else {
          console.log(error);
        }
      } else {
        setAllHotels(hotels);
      }
    })();
  }, [authFetch, hotels]);

  useEffect(() => {
    let shouldSet = true;
    if (year !== undefined && month !== undefined && shouldSet) {
      let date = selectedDate;
      let val = date.getDay();
      let newArray = new Array(val === 0 ? 6 : val - 1).fill({
        cityColor: "#fff",
        cityDemandLevelSymbol: "",
        color: "",
        createdOn: "",
        date: "",
        description: "",
        hotelDemandLevelSymbol: "",
        hotelId: -1,
        id: -1,
        isActual: false,
        isDeleted: false,
        occupancyPercentage: 0,
        systemDemandLevelColor: "#fff",
        systemDemandLevelSymbol: "",
      });

      if (hotelDayDetail?.baseDemandLevels !== undefined) {
        setTempArray([...newArray, ...hotelDayDetail?.baseDemandLevels]);
      }
    }
    return () => {
      shouldSet = false;
    };
  }, [year, month, hotelDayDetail]);

  useEffect(() => {
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].isActual === false) {
        tempArray[i].cityColor = "white";
      }
      if (
        tempArray[i].cityDemandLevelSymbol === "" &&
        tempArray[i].isActual === true
      ) {
        tempArray[i].cityColor = "#778899";
      }
    }
  }, [tempArray]);

  const handleCurrentMonth = useCallback(() => {
    setSelectedDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );
  }, []);

  const handleIncrementDecrementDate = useCallback((tag) => {
    if (tag === "left") {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      setSelectedDate((selectedDate) => new Date(year, month - 1, 1));
    } else if (tag === "right") {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      setSelectedDate((selectedDate) => new Date(year, month + 1, 1));
    }
  });

  const handleDLChange = (val) => {
    setSelectDL(val);
    const checkCityDL = val?.some((value) => value === "CityDemandLevel");
    const checkCityEvent = val?.some((value) => value === "CityEvent");
    setUpdateSelectedDL({
      CityDemandLevel: checkCityDL,
      CityEvent: checkCityEvent,
    });
    setDLCheckbox(checkCityEvent || checkCityDL ? true : false);
  };

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "eventsCalendarView") {
        eventsCalendarView = permission[key];
      }
      if (permission[key].name === "addEvent") {
        addEvent = permission[key];
      }
      if (permission[key].name === "addDemandLevel") {
        addDemandLevel = permission[key];
      }
      if (permission[key].name === "addCityDemandLevel") {
        addCityDemandLevel = permission[key];
      }
      if (permission[key].name === "eventCalendarCopy") {
        eventCalendarCopy = permission[key];
      }
      if (permission[key].name === "acrossHotel") {
        isAdmin = true;
      }
    }
  }

  return currentHotel?.isPreciumEnabled ? (
    <Paper elevation={0} sx={{ color: "white !important" }}>
      <Wrapper>
        <ForecastPage style={{ background: "#fff" }}>
          {/* page header */}
          <Paper
            elevation={0}
            style={{
              width: "84vw",
              padding: "10px 0px 10px 6px",
              margin: "0 auto",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <Paper
                elevation={2}
                style={{
                  padding: "4px 12px 9px 12px",
                  height: "40px",
                  width: "230px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  <MonthPicker
                    value={selectedDate}
                    onChange={setSelectedDate}
                  />
                </Stack>
              </Paper>
              <Paper
                elevation={2}
                style={{
                  padding: "4px 12px 9px 12px",
                  height: "40px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <Stack direction={"row"} spacing={-1}>
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrementDate("left");
                    }}
                  >
                    <ArrowBackIosIcon
                      style={{ fontSize: "14px", color: "rgb(48,111,188)" }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrementDate("right");
                    }}
                  >
                    <ArrowForwardIos
                      style={{ fontSize: "14px", color: "rgb(48,111,188)" }}
                    />
                  </IconButton>
                </Stack>
              </Paper>
              <Paper
                style={{
                  width: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  userSelect: "none",
                  padding: "9px 12px 9px 12px",
                  height: "40px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
                onClick={handleCurrentMonth}
              >
                <div>
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Current Month
                  </Typography>
                </div>
              </Paper>
              <Paper
                onClick={handleIndexState}
                style={{
                  padding: "9px 12px 9px 12px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  userSelect: "none",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  spacing={1}
                >
                  <Info
                    style={{ color: "rgb(48,111,188)", fontSize: "20px" }}
                  />
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Index
                  </Typography>
                </Stack>
              </Paper>
              <Paper
                onClick={handleCopy}
                style={{
                  padding: "9px 12px 9px 12px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  userSelect: "none",
                  background: "rgb(48,111,188)",
                  width: "120px",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  spacing={1}
                >
                  <ContentCopy style={{ color: "#fff", fontSize: "20px" }} />
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#fff",
                    }}
                  >
                    Copy
                  </Typography>
                </Stack>
              </Paper>
              <Paper
                elevation={2}
                style={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "9px 12px 9px 12px",
                  cursor: "pointer",
                  userSelect: "none",
                  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                }}
                onClick={() => {
                  setCityDemandLevelState(true);
                }}
              >
                <Stack>
                  <Typography
                    variant="h4"
                    style={{
                      fontstyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    Set Demand Levels
                  </Typography>
                </Stack>
              </Paper>
            </Stack>
          </Paper>
          <MonthDayCard
            hasData={hasData}
            isMonthUpdated={isMonthUpdated}
            baseDemandLevels={tempArray}
            demandLevels={demandLevels}
            isInEditMode={isInEditMode}
            toggleEdit={toggleEdit}
            onChange={onChange}
            setLoading={setLoading}
            setSetModalOpen={setSetModalOpen}
            generateBatchBaseDemandLevels={generateBatchBaseDemandLevels}
            addEvent={addEvent}
            addDemandLevel={addDemandLevel}
            addCityDemandLevel={addCityDemandLevel}
            isApiHit={isApiHit}
            Loading={Loading}
            getData={getData}
            uploadImage={uploadImage}
            sendRemark={sendRemark}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            remarkData={remarkData}
            setRemarkData={setRemarkData}
            popoverData={popoverData}
            setPopOverData={setPopOverData}
          />
          <Dialog onClose={handleIndexState} open={indexState}>
            <IndexModal
              isOpen={indexState}
              setOpen={setIndexState}
              onClose={handleIndexState}
            />
          </Dialog>
          <Dialog
            open={copyOpen}
            onClose={() => {
              setCopyOpen(false);
            }}
          >
            <NewCalendarCopyModal
              setCopyFromHotelId={setCopyFromHotelId}
              isOpen={copyOpen}
              setOpen={setCopyOpen}
              hotellist={allhotels}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setCheckbox={setCheckbox}
              onClose={handleCopy}
              selectDL={selectDL}
              handleDLChange={handleDLChange}
              dLCheckbox={dLCheckbox}
              dateRangeState={dateRangeState}
              setDateRangeState={setDateRangeState}
              setIsChangeDl={setIsChangeDl}
              currentHotelId={currentHotelId}
            />
          </Dialog>
          {cityDemandLevelState && (
            <NewCalendarCityDemandModal
              isOpen={cityDemandLevelState}
              setSetModalOpen={setCityDemandLevelState}
              demandLevels={demandLevels}
              onSave={generateBatchBaseDemandLevels}
              addDemandLevel={addDemandLevel}
              addCityDemandLevel={addCityDemandLevel}
              getData={getData}
              selectedDate={selectedDate}
            />
          )}
        </ForecastPage>
      </Wrapper>
    </Paper>
  ) : (
    <PreciumDisabledPage />
  );
};
